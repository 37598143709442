import { Button, Grid, Link } from '@mui/material';
import React, { useCallback, useContext, useMemo } from 'react';
import { UserContext } from 'components/UserGuard';
import { ConfigContext } from 'components/ConfigGuard';
import { SiteConfigView } from 'store/types/SiteConfig';
import routes from 'store/configs/Routes';
import { getHashRouteUrl, getLoginPath } from 'util/Route';
import Card from 'components/shared/Card';
import authClient from 'services/AuthService';
import { tenantConfig } from 'config';
import { defaultGridContainerProps, defaultGridItemProps } from 'util/Layout';
import AuthService from 'services/AuthService';

import pageStyles from '../DonationPaymentPageView.module.scss';
import styles from './DonationUserLoginSection.module.scss';

interface DonationUserLoginSectionProps {
  donationId: string;
}

const DonationUserLoginSection: React.FunctionComponent<DonationUserLoginSectionProps> = ({ donationId }) => {
  const currentUser = useContext(UserContext);
  const {
    modulesSettings: {
      donations: { enableGuestCheckout },
    },
  }: SiteConfigView = useContext(ConfigContext);
  const { logoutDonationUrl = '' } = tenantConfig;
  const hasAuthorizedUser: boolean = useMemo(() => !!currentUser.id, [currentUser]);
  const tempToken: string = useMemo(() => AuthService.getIdToken(), []);

  const handleLogOut = useCallback(() => {
    authClient.logout(`${logoutDonationUrl}${donationId}&id_token_hint=${tempToken}`);
  }, [logoutDonationUrl, donationId, tempToken]);

  return (
    <>
      <Card contentClassName={pageStyles.cardContent}>
        <span className={styles.loginContent}>
          {hasAuthorizedUser ? (
            <Grid {...defaultGridContainerProps} justifyContent={'space-between'}>
              <Grid {...defaultGridItemProps} sm={'auto'}>
                {'Welcome, '}
                <Link className={styles.link} href={getHashRouteUrl(routes.profile)}>
                  {currentUser.firstName}!
                </Link>
              </Grid>
              <Grid {...defaultGridItemProps} sm={'auto'}>
                <Link className={styles.link} onClick={handleLogOut}>
                  {'Log Out'}
                </Link>
              </Grid>
            </Grid>
          ) : enableGuestCheckout ? (
            <>
              {'Already a member? '}
              <Link className={styles.link} href={getLoginPath(`${routes.makeDonation}/${donationId}`)}>
                {'Sign In or Create New Account.'}
              </Link>
            </>
          ) : (
            <Grid {...defaultGridContainerProps} justifyContent={'space-between'}>
              <Grid {...defaultGridItemProps} className={styles.loginPrompt}>
                Please log in or register to complete your donation.
              </Grid>
              <Grid {...defaultGridItemProps}>
                <Button
                  variant={'contained'}
                  color={'secondary'}
                  href={getLoginPath(`${routes.makeDonation}/${donationId}`)}
                >
                  {'Sign or Create Account'}
                </Button>
              </Grid>
            </Grid>
          )}
        </span>
      </Card>
    </>
  );
};
export default DonationUserLoginSection;
