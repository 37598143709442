import { Alert } from '@mui/material';
import Spinner from 'components/shared/Spinner';
import React, { useCallback, useEffect, useState } from 'react';
import UserService from 'services/api/UserService';
import SiteConfig, { ModulesSettingsConfig, SiteConfigView } from 'store/types/SiteConfig';
import useRequest from 'hooks/useRequest';
import SiteTheme from 'store/enums/SiteTheme';
import { tenantConfig } from 'config';
import { modulesRequirePayment } from 'util/Route';

import commonStyles from 'styles/common.module.scss';

const getDefaultModulesSettingsData = (data?: ModulesSettingsConfig): ModulesSettingsConfig => ({
  profile: {
    enableMyEmployer: data?.profile?.enableMyEmployer,
    enableRelationship: data?.profile?.enableRelationship,
    enablePreferences: data?.profile?.enablePreferences,
    enableAdvocacy: data?.profile?.enableAdvocacy,
  },
  companyManagement: {
    enableAdvocacy: data?.companyManagement?.enableAdvocacy,
  },
  settings: {
    enableAccountSettings: data?.settings?.enableAccountSettings,
    enableEmailNotifications: data?.settings?.enableEmailNotifications,
    enablePortalManagement: data?.settings?.enablePortalManagement,
  },
  personalPayments: {
    enablePayInvoices: data?.personalPayments?.enablePayInvoices,
    enablePaySalesOrders: data?.personalPayments?.enablePaySalesOrders,
  },
  companyPayments: {
    enablePaySalesOrders: data?.companyPayments?.enablePaySalesOrders,
  },
  addresses: {
    enableSelectDefaultAddress: data?.addresses?.enableSelectDefaultAddress,
  },
  home: {
    enableNavigationSection: data?.home?.enableNavigationSection,
    enableNotes: data?.home?.enableNotes,
    navigationLinks: data?.home?.navigationLinks,
  },
  subscriptions: {
    enableDonations: data?.subscriptions?.enableDonations,
    numOfDaysToRenew: data?.subscriptions?.numOfDaysToRenew || 0,
  },
  partner: {
    applicationTypeId: data?.partner?.applicationTypeId,
  },
  payments: {
    enableSalesOrderHistory: data?.payments?.enableSalesOrderHistory,
    enableRecaptcha: data?.payments?.enableRecaptcha,
    events: {
      isAchEnabled: data?.payments?.events?.isAchEnabled,
      isCreditCardEnabled: data?.payments?.events?.isCreditCardEnabled,
    },
    invoices: {
      isAchEnabled: data?.payments?.invoices?.isAchEnabled,
      isCreditCardEnabled: data?.payments?.invoices?.isCreditCardEnabled,
    },
    donations: {
      isAchEnabled: data?.payments?.donations?.isAchEnabled,
      isCreditCardEnabled: data?.payments?.donations?.isCreditCardEnabled,
    },
    applications: {
      isAchEnabled: data?.payments?.applications?.isAchEnabled,
      isCreditCardEnabled: data?.payments?.applications?.isCreditCardEnabled,
    },
    products: {
      isAchEnabled: data?.payments?.products?.isAchEnabled,
      isCreditCardEnabled: data?.payments?.products?.isCreditCardEnabled,
    },
  },
  donations: {
    predefinedAmounts: data?.donations?.predefinedAmounts || [],
    defaultFeePercent: data?.donations?.defaultFeePercent || 0,
    isParentCampaignAllowed: data?.donations?.isParentCampaignAllowed,
    enableGuestCheckout: data?.donations?.enableGuestCheckout,
  },
  nccer: {
    portalHeader: data?.nccer?.portalHeader || '',
    enableCardNumber: data?.nccer?.enableCardNumber,
  },
  events: {
    pageTitle: data?.events?.pageTitle || '',
    pageSubTitle: data?.events?.pageSubTitle || '',
  },
  memberDirectory: {
    pageTitle: data?.memberDirectory?.pageTitle || '',
    pageSubTitle: data?.memberDirectory?.pageSubTitle || '',
    enableMarketSegments: data?.memberDirectory?.enableMarketSegments,
  },
  publicCommittees: {
    pageTitle: data?.publicCommittees?.pageTitle || '',
    pageSubTitle: data?.publicCommittees?.pageSubTitle || '',
  },
  expenseProfile: {
    profileTitle: data?.expenseProfile?.profileTitle || '',
    profilePaymentOptionsTitle: data?.expenseProfile?.profilePaymentOptionsTitle || '',
    profileTaxFilesTitle: data?.expenseProfile?.profileTaxFilesTitle || '',
  },
  expenseReport: {
    reportsTitle: data?.expenseReport?.reportsTitle || '',
    reportExpensesTitle: data?.expenseReport?.reportExpensesTitle || '',
  },
});

export const defaultStaticContext: SiteConfigView = {
  enabledModules: [],
  quickLinks: [],
  sidebar: [],
  isNccerSsoApp: tenantConfig.theme === SiteTheme.NccerSSO,
  isNccerBillingApp: tenantConfig.theme === SiteTheme.NccerBilling,
  isNccerTheme: tenantConfig.theme === SiteTheme.NccerSSO || tenantConfig.theme === SiteTheme.NccerBilling,
  isMemberplexTheme: tenantConfig.theme === SiteTheme.MemberPlex,
  isGamsdTheme: tenantConfig.theme === SiteTheme.Gamsd,
  isSnaTheme: tenantConfig.theme === SiteTheme.Sna,
  isAcsTheme: tenantConfig.theme === SiteTheme.Acs,
  isSmacnaTheme: tenantConfig.theme === SiteTheme.Smacna,
  isCompassTheme: tenantConfig.theme === SiteTheme.Compass,
  isTeamsEliteTheme: tenantConfig.theme === SiteTheme.TeamsElite,
  isDefaultTheme: tenantConfig.theme === SiteTheme.Default,
  modulesSettings: getDefaultModulesSettingsData(),
};

export const ConfigContext = React.createContext<SiteConfigView>(defaultStaticContext);

const ConfigGuard: React.FunctionComponent = ({ children }) => {
  const [staticData, setStaticData] = useState<SiteConfigView>(defaultStaticContext);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const { error, firstLoading, data } = useRequest<SiteConfig>(UserService.getSiteConfig);
  const [scriptLoading, setScriptLoading] = useState<boolean>(false);

  const initScriptLoading = useCallback(() => {
    const script = document.createElement('script');

    setScriptLoading(true);
    script.type = 'text/javascript';
    script.src = 'https://secure.networkmerchants.com/token/Collect.js';
    script.async = true;
    script.setAttribute('data-tokenization-key', tenantConfig.paymentToken);
    script.onload = () => {
      setScriptLoading(false);
    };
    document.body.appendChild(script);
  }, []);

  useEffect(() => {
    setErrorMessage(error || '');
  }, [error]);

  useEffect(() => {
    if (data) {
      const { enabledModules = [], quickLinks = [], sidebar = [], modulesSettings } = data;

      if (modulesRequirePayment(enabledModules)) {
        initScriptLoading();
      }
      setStaticData((prevState) => ({
        ...prevState,
        enabledModules,
        quickLinks,
        sidebar,
        modulesSettings: getDefaultModulesSettingsData(modulesSettings),
      }));
    }
  }, [data, initScriptLoading]);

  return firstLoading || scriptLoading ? (
    <Spinner loading={true} fullPage={true} transparent={false} />
  ) : error ? (
    <Alert severity={'error'} className={commonStyles.alert}>
      {errorMessage}
    </Alert>
  ) : (
    <ConfigContext.Provider value={staticData}>{children}</ConfigContext.Provider>
  );
};
export default ConfigGuard;
